/**
 * STATUSES REFERENCE:
 * https://simpatra.atlassian.net/wiki/spaces/SP/pages/1888190472/Webhooks
 */
const INVOICE_STATUSES = {
  FULFILLMENT_STATUSES: {
    PENDING: {
      statusId: 0,
      value: "Pending",
    },
    PROCESSING: {
      statusId: 31,
      value: "Processing",
    },
    IN_TRANSIT: {
      statusId: 220,
      value: "In Transit",
    },
    DELIVERED: {
      statusId: 240,
      value: "Delivered",
    },
    // INTRANSIT TO PATIENT IS FOR MEDCENTER STATUS
    KIT_REGISTERED: {
      statusId: 291,
      value: "Kit Registered",
    },
    IN_TRANSIT_TO_PATIENT: {
      statusId: 292,
      value: "In Transit (to Patient)",
    },
    OUT_FOR_DELIVERY: {
      statusId: 230,
      value: "OUT FOR DELIVERY",
    },
    DELIVERED_TO_PATIENT: {
      statusId: 293,
      value: "Delivered (to Patient)",
    },
    IN_TRANSIT_TO_LABORATORY: {
      statusId: 294,
      value: "In Transit (to Laboratory)",
    },
    DELIVERED_TO_LABORATORY: {
      statusId: 295,
      value: "Delivered (to Laboratory)",
    },
    UNKNOWN: {
      statusId: null,
      value: "Unknown",
    },
    MISSING: {
      statusId: null,
      value: "Missing",
    },
  },
  LAB_ORDER_FULFILLMENT_STATUSES: {
    PENDING_RESULTS: {
      statusId: 300,
      value: "Pending For Results",
    },
    RESULTS_COMPLETED: {
      statusId: 310,
      value: "Results Ready",
    },
    COMPLETED: {
      value: "Completed",
    },
  },
  ORDER_STATUSES: {
    IN_PROGRESS: {
      statusId: null,
      value: "In Progress",
    },
    COMPLETED: {
      value: "Completed",
    },
    DELIVERED: {
      statusId: null,
      value: "Delivered",
    },
    CANCELLED: {
      statusId: 100,
      value: "Cancelled",
    },
    REFUNDED: {
      statusId: 110,
      value: "Refunded",
    },
    PURCHASE_FAILED: {
      statusId: 22,
      value: "Associated Purchase Failed",
    },
  },
};

export default {
  INVOICE_STATUSES,
};
