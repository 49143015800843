export default {
  FEDEX: {
    id: 0,
    value: "FEDEX",
  },
  UPS: {
    id: 1,
    value: "UPS",
  },
  USPS: {
    id: 2,
    value: "USPS",
  },
};
