<style lang="scss" scoped>
@import "./VerticalNormalForm.scss";
</style>
<template>
  <div class="vertical-normal-form">
    <label> {{ formLabel }}</label>
    <slot name="form-field" />
  </div>
</template>
<script>
export default {
  name: "VerticalNormalForm",
  props: {
    formLabel: {
      type: String,
      required: true,
    },
  },
};
</script>
