<style lang="scss" scoped>
@import "./support-options.scss";
</style>

<template>
  <div class="support-list">
    <div v-for="option in supportOptions" :key="option.support_title">
      <BaseCard v-if="displaySupportOption(option)" class="service-option">
        <div class="icon-wrapper">
          <i :class="option.support_icon" />
        </div>
        <h3 class="option-title">{{ option.support_title }}</h3>
        <p class="option-description">{{ option.support_subtext }}</p>
        <BaseButton class="option-select" type="secondary" @click="redirectTo(option.support_redirect_to)">
          {{ option.support_button_text }}
        </BaseButton>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseCard from "@/components/common/BaseCard.vue";
import BaseButton from "@/components/common/BaseButton.vue";

export default {
  name: "SupportOptions",
  components: {
    BaseCard,
    BaseButton,
  },
  data() {
    return {
      // Update this list if a new support service is added
      supportOptions: [
        {
          support_icon: "el-icon-service",
          support_title: "Support",
          support_subtext:
            "Email our support team to get help with troubleshooting or to provide feedback on our products and services.",
          support_redirect_to: "email",
          support_button_text: "Email support@simpatra.com",
          check_dosing_permission: false,
        },
        {
          support_icon: "el-icon-chat-line-square",
          support_title: "Provider Consults",
          support_subtext:
            "Start a conversation with one of our in-house medical experts to get BHRT patient and dosing related advise.",
          support_redirect_to: "consultation",
          support_button_text: "Go to provider consultations",
          check_dosing_permission: true,
        },
        {
          support_icon: "el-icon-chat-line-square",
          support_title: "Provider Articles",
          support_subtext:
            "Get the Answers you need in our Support Articles Portal for BHRT patient and dosing related advise.",
          support_redirect_to: "articles",
          support_button_text: " Go to provider articles",
          check_dosing_permission: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getOrgId"]),
    ...mapGetters("accountsModule", ["getOrgDosingUsers"]),
    ...mapGetters("usersModule", ["getMyUserId"]),
    userHasDosingPermission() {
      return this.getOrgDosingUsers.some((dosingUser) => {
        return dosingUser.user.id === this.getMyUserId;
      });
    },
  },
  async mounted() {
    if (this.getOrgDosingUsers.length === 0) {
      await this.fetchOrgDosingUsers({ orgId: this.getOrgId });
    }
  },
  methods: {
    ...mapActions("accountsModule", ["fetchOrgDosingUsers"]),
    displaySupportOption(option) {
      // Check for special cases support options
      if (option.check_dosing_permission) {
        return this.userHasDosingPermission;
      }
      return true;
    },
    redirectTo(page) {
      switch (page) {
        case "email":
          window.location.href = "mailto:support@simpatra.com";
          break;
        case "consultation":
          this.$router.push({ name: "DosingConsultations" });
          break;
        case "articles":
          // TODO: Move to a config if there are lower envs
          window.open("https://support.simpatra.com/support/home", "_blank");
          break;
        default:
          this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>
